import React from "react"
import HubspotForm from "react-hubspot-form"
import { Helmet } from "react-helmet"
import LazyLoad from "react-lazy-load"

const awardsIcons = [
  "2024/05/Gartner-badge.webp",
  "2024/05/Brandon-hall-gold.webp",
  "2024/05/Brandon-hall-silver.webp",
  "2024/05/Bakers-dozen-gold.webp",
  "2024/07/g2-6.png",
  "2024/07/g2-7.png",
  "2024/07/g2-9.png",
  "2024/07/g2-11.png",
  "2024/07/g2-12.png"
]

const badgeIcons = [
  "2024/07/g2-6.png",
  "2024/07/g2-7.png",
  "2024/07/g2-9.png",
  "2024/07/g2-11.png",
  "2024/07/g2-12.png"
]

function RequestPage() {
  return (
    <>

      <Helmet>
          <title>Request a demo | Vantage Circle
          </title>

          <meta
            name="description"
            content="Discover how Vantage Circle's platform enhances employee engagement through rewards, recognition, wellness, and feedback. Schedule your personalized demo today."
          />
          <meta property="twitter:title" content="Request a demo | Vantage Circle" />
          <meta name="robots" content="index" />

          <meta
            property="twitter:description"
            content="Discover how Vantage Circle's platform enhances employee engagement through rewards, recognition, wellness, and feedback. Schedule your personalized demo today."
          />

          <meta property="og:type" content="website" />

          <meta
            property="og:url"
            content="https://www.vantagecircle.com/request-demo/"
          />

          <meta property="og:site_name" content="Vantage Circle" />
          <meta property="og:title" content="Request a demo | Vantage Circle" />

          <meta
            property="og:description"
            content="Discover how Vantage Circle's platform enhances employee engagement through rewards, recognition, wellness, and feedback. Schedule your personalized demo today."
          />
          <link
            rel="canonical"
            href="https://www.vantagecircle.com/request-demo/"
          ></link>
        </Helmet>

      <section id="request-demo" className="bg-white">
        <div className="container max-w-7xl pt-10 md:pt-10 mx-auto px-6 py-10 newreqform">
          <div className="flex flex-col place-content-center xl:flex-row">
            <div className="card-1 w-full xl:w-7/12 mb-10">
              <div className="items-center pb-5">
                <img
                  decoding="async"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/07/new-vc-logo.png"
                  width="250px"
                  alt="Vantage Circle Logo"
                />
              </div>

              <h1 className="text-gray-500 lg:pr-3 mt-0 lg:mt-4 xl:w-full  text-2xl lg:text-4xl xl:text-4xl text-center lg:text-left">
              Enhance employee engagement holistically with our behavioral science-powered platform
              </h1>

              <p className="mt-5 lg:mt-10 text-gray-900 font-semibold text-base text-center md:text-left">
                As we guide you through, we will delve into :
              </p>

              <ul className="list-inside orangebullets text-gray-250 lato text-sm xl:text-xl md:px-5 xl:px-0 w-full lg:w-full text-left pb-5 xl:pb-2">
                <li className="text-gray-500">
                  A brief exploratory conversation to understand how Vantage
                  Circle can help you drive desirable employee behaviors
                  through recognition.
                </li>
                <li className="text-gray-500">
                  A thorough walk-through of our products, enriched with
                  insights and relevant case studies.
                </li>
                <li className="text-gray-500">
                  A discussion aimed at aligning our solutions with your
                  business objectives to chart the best course forward.
                </li>
              </ul>

              <div className="justify-center items-center pb-10 xl:w-11/12">
                {/* <img
                  decoding="async"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/12/award-badges-strip-1.png"
                  alt="Award Badges"
                /> */}
                <div className="w-full md:w-10/12 xl:w-11/12 mx-auto grid grid-cols-3 md:grid-cols-4 gap-10 md:gap-16 xl:gap-10 place-content-center my-5">
                  {awardsIcons.map((awardIcon, index) => {
                    return (
                      <div
                        className={
                          index > 3
                            ? "flex place-content-center xl:hidden"
                            : "flex place-content-center"
                        }
                      >
                        <picture className="my-auto">
                          <source
                            className="z-10 relative"
                            media="(min-width: 1025px)"
                            srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_200/q_auto/gatsbycms/uploads/${awardIcon}`}
                            type="image/webp"
                            alt="Vantage Rewards"
                            width="140"
                          />
                          <source
                            className="z-10 relative"
                            srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_100/q_auto/gatsbycms/uploads/${awardIcon}`}
                            type="image/webp"
                            alt="Vantage Rewards"
                            width="100"
                          />
                          <img
                            className="z-10 relative  mx-auto lg:mx-0"
                            src={`https://res.cloudinary.com/vantagecircle/image/upload/w_80/q_auto/gatsbycms/uploads/2024/05/${awardIcon}`}
                            alt="Vantage Rewards"
                            width="50"
                          />
                        </picture>
                      </div>
                    )
                  })}
                </div>
                <div className="w-full md:w-10/12 xl:w-full mx-auto grid grid-cols-3 md:grid-cols-5 gap-10 md:gap-16 xl:gap-10 place-content-center mt-5 mb-5">
                  {badgeIcons.map(awardIcon => {
                    return (
                      <div className="xl:flex hidden place-content-center">
                        <picture className="my-auto">
                          <source
                            className="z-10 relative"
                            media="(min-width: 1025px)"
                            srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_200/q_auto/gatsbycms/uploads/${awardIcon}`}
                            type="image/webp"
                            alt="Vantage Rewards"
                            width="140"
                          />
                          <source
                            className="z-10 relative"
                            srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_100/q_auto/gatsbycms/uploads/${awardIcon}`}
                            type="image/webp"
                            alt="Vantage Rewards"
                            width="100"
                          />
                          <img
                            className="z-10 relative  mx-auto lg:mx-0"
                            src={`https://res.cloudinary.com/vantagecircle/image/upload/w_80/q_auto/gatsbycms/uploads/2024/05/${awardIcon}`}
                            alt="Vantage Rewards"
                            width="50"
                          />
                        </picture>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>

            <div className="flex items-start xl:w-5/12">
              <div className="shortenFormDesign flex flex-col justify-start xl:pt-20">
                <div className="card-1 flex xl:ml-8 p-6 pl-2 xl:pl-6 req-demo bg-purple-30 rounded-xl text-gray-900 w-11/12 mx-auto xl:w-full border">
                  <div className="form-section pl-3 pr-3 xl:pl-6 xl:py-10 rounded-lg ">
                    <h2 className="mt-2 mb-4 text-lg md:text-xl xl:text-2xl text-gray-500 text-center">
                      Book a personalized demo for your unique business needs{" "}
                    </h2>
                    <LazyLoad>
                      <HubspotForm
                        portalId="6153052"
                        formId="65d41591-6d6e-4fe7-b2aa-59612c13a956"
                        onSubmit={() => console.log("Submit!")}
                        onReady={form => console.log("Form ready!")}
                        loading={<div>Loading...</div>}
                      />
                    </LazyLoad>
                    <div className="pt-5">
                      <div className="w-full justify-center items-center flex flex-col pb-10">
                        <p className="text-gray-250 text-base mx-auto mb-0 text-center">
                          Trusted by <span className="text-orange">700+</span>{" "}
                          organizations, including Fortune 500
                        </p>
                      </div>
                      <picture>
                        <source
                          className="z-10 relative"
                          loading="lazy"
                          srcSet="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/12/demo-clients-strip-1.png"
                          alt="Vantage Rewards"
                        />
                        <img
                          className="z-10 relative"
                          loading="lazy"
                          src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/12/demo-clients-strip-1.png"
                          alt="Vantage Rewards"
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default RequestPage
